import React, { useEffect, useState } from 'react';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';


function StatGenerale() {
    /*#########################[Const]##################################*/
    

    /*#########################[Use Effect]##################################*/
    

    /*#########################[Functions]##################################*/
    

    /*#########################[Component]##################################*/ 
    


    return (  <>
        <BackCard data={InputLinks.backCard.sg}/>
        <br />
        <div className='container-fluid'>
            Stat Generale
        </div>
 
    </>);
}


export default StatGenerale;