import React, { useEffect, useState } from 'react';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';

function StatRegionale() {
    /*#########################[Const]##################################*/
    

    /*#########################[Use Effect]##################################*/
    

    /*#########################[Functions]##################################*/
    

    /*#########################[Component]##################################*/ 
    


    return (  <>
        <BackCard data={InputLinks.backCard.sr}/>
        <br />
        <div className='container-fluid'>
            StatRegionale
        </div>
 
    </>);
}


export default StatRegionale;