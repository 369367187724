import React, { useEffect, useRef, useState } from 'react';


function PannierPage() {
     
    return ( <>
            PannierPage
        </> );
}

export default PannierPage;